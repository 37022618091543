import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const qcMenu = [
  {
    subheader: "general",
    items: [
      {
        title: "QC",
        icon: ICONS.alerts,
        children: [
          {
            title: "QC Listing",
            path: PATH_DASHBOARD.general.qcListing,
            icon: ICONS.grid,
          },
          {
            title: "QC Form",
            path: PATH_DASHBOARD.general.qcForm,
            icon: ICONS.grid,
          },
        ],
      },
     
    ],
  },
];

export { qcMenu };
