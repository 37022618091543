import moment from "moment";
import Page from "../../../components/Page";
import { collectionAgentType } from "../../../service/api";
import { useState, useEffect, useRef } from "react";
import * as React from "react";
import {
  Container,
  IconButton,
  Button,
  Divider,
  Autocomplete,
  Typography,
  Paper,
  TextField,
  Box,
  CircularProgress,
} from "@mui/material";
import "src/styles/appAdminDashboard.css";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import { CSVLink } from "react-csv";
import Iconify from "src/components/Iconify";
import { useDispatch, useSelector } from "react-redux";
import { collectionReport } from "src/redux/slices/collectionAgentReportState";
import SearchIcon from "@mui/icons-material/Search";
import useSettings from "src/hooks/useSettings";
import CollectionTable from "./CollectionTable";
import LoadShareTable from "./LoadShareTable";
import { collAgentScanReport } from "./../../../service/api";
import { useSnackbar } from "notistack";

export default function Inventory() {
  const [loading, setLoading] = useState(false);
  const [loader, setLoader] = useState(false);
  const [user, setUser] = useState();

  const [downloadData, setDownloadData] = useState([]);
  const [collectionAgent, setCollectionAgent] = useState([]);
  const [agent, setAgent] = useState("");
  const csvInstance = useRef(null);
  const { enqueueSnackbar } = useSnackbar();
  const { themeStretch } = useSettings();
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );

  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));

  const dispatch = useDispatch();

  const { data, isLoading } = useSelector(
    (state) => state.collectionAgentReportData
  );

  const getCollectionAgentInfo = async () => {
    setLoading(true);
    const collectionDataResponse = await dispatch(
      collectionReport(
        agent,
        fromDate,
        toDate,
        localStorage.getItem("userTypeId"),
        moment().format("YYYY-MM-DD")
      )
    );
    setLoading(false);
  };

  const reportDownload = async () => {
    setLoader(true);
    try {
      let res = await collAgentScanReport(fromDate, toDate);
      // console.log("COLL AGENT REPORT DATA", res);
      if (res.status_code === 200) {
        setDownloadData(res.data);
        csvInstance.current.link.click();
        setLoader(false);
      } else {
        setLoader(false);
        enqueueSnackbar("Something went wrong", { variant: "error" });
      }
    } catch (error) {
      console.log(error);
      setLoader(false);
      enqueueSnackbar("Download Error", { variant: "error" });
    }
  };

  useEffect(async () => {
    //getting collection agent menu on page load

    setUser(localStorage.getItem("userTypeId"));

    let collectionagent = await collectionAgentType();
    // console.log("collectionAgent", collectionagent);
    setCollectionAgent(collectionagent);
    setLoading(false);
  }, []);

  const handleAgent = (event) => {
    setAgent(event.target.value);
  };

  return (
    <Page title="Collection Agent Report">
      <Container maxWidth={themeStretch ? false : "xl"}>
        <Typography variant="h4" sx={{ mb: 5 }}>
          Collection Agent Report
        </Typography>
        {user === "9" ? (
          <LoadShareTable agent={agent} fromDate={fromDate} toDate={toDate} />
        ) : null}

        <div
          style={{
            marginTop: "3rem",
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
            justifyContent: "center",
          }}
        >
          <Paper elevation={10} className="search-bar">
            <div
              style={{
                display: "flex",
                alignItems: "center",
                margin: "1vh auto",
              }}
            >
              <Button
                onClick={reportDownload}
                sx={{ textDecoration: "none" }}
                variant="contained"
                startIcon={!loader && <Iconify icon={"eva:download-outline"} />}
              >
                <Typography sx={{ textDecoration: "none" }}>
                  {loader ? (
                    <CircularProgress
                      style={{ margin: "0rem 1rem" }}
                      color="inherit"
                      size="1rem"
                    />
                  ) : (
                    "Download Actual scanned report"
                  )}
                </Typography>
              </Button>

              <CSVLink
                asyncOnClick={true}
                data={downloadData}
                filename={`${fromDate}to${toDate}_ActualScannedReport`}
                ref={csvInstance}
              />
            </div>

            <div className="filterline2">
              <Autocomplete
                disableClearable
                id="combo-box-demo"
                options={collectionAgent}
                getOptionLabel={(agent) => agent.username}
                getOptionSelected={(option, value) =>
                  option.username === value.username
                }
                renderInput={(params) => (
                  <TextField
                    {...params}
                    sx={{ minWidth: 200 }}
                    label="Collection Agent"
                    size="small"
                  />
                )}
                onChange={(event, newValue) => {
                  setAgent(newValue.username);
                }}
              />
              <Divider
                className="divider"
                style={{ margin: 4 }}
                orientation="vertical"
              />

              <LocalizationProvider
                className="from-date"
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  //   onKeyPress={handleKeypress}
                  label="From Date"
                  inputFormat="dd/MM/yyyy"
                  value={fromDate}
                  onChange={(newValue) => {
                    const date = moment(newValue).format("YYYY-MM-DD");

                    setFromDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField size={"small"} {...params} />
                  )}
                />
              </LocalizationProvider>
              <Divider
                className="divider"
                style={{ margin: 5 }}
                orientation="vertical"
              />

              <LocalizationProvider
                className="from-date"
                dateAdapter={AdapterDateFns}
              >
                <DesktopDatePicker
                  label="To Date"
                  inputFormat="dd/MM/yyyy"
                  value={toDate}
                  onChange={(newValue) => {
                    const date = moment(newValue).format("YYYY-MM-DD");
                    setToDate(date);
                  }}
                  renderInput={(params) => (
                    <TextField size={"small"} {...params} />
                  )}
                />
              </LocalizationProvider>

              <div
                style={{
                  display: "flex",
                  marginLeft: "10px",
                  flexDirection: "row",
                  justifyContent: "center",
                }}
              >
                {data.orderDetails && (
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      justifyContent: "center",
                    }}
                  >
                    <CSVLink
                      data={data.orderDetails}
                      filename={`${fromDate}to${toDate}_collectionAgentReport`}
                    >
                      <IconButton color="primary">
                        <Iconify icon={"eva:download-outline"} />
                      </IconButton>
                    </CSVLink>

                    <Typography style={{ fontSize: 11 }}>Download</Typography>
                  </div>
                )}
                <Divider
                  className="divider"
                  style={{ margin: "1vh auto" }}
                  orientation="vertical"
                />

                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    alignItems: "center",
                    justifyContent: "center",
                    minWidth: 150,
                  }}
                >
                  <Button
                    className="searchButton"
                    style={{ margin: 3 }}
                    onClick={getCollectionAgentInfo}
                  >
                    <SearchIcon className="searchButtonIcon" />
                  </Button>
                  <Typography style={{ fontSize: 11 }}>
                    Click here to search
                  </Typography>
                </div>
              </div>
            </div>
          </Paper>
        </div>

        {!loading ? (
          <>
            {/* <ContainersDetails />
            <ContainersCountTimeSlot />
            <OrdersCountTimeSlot /> */}
            <CollectionTable date={toDate} />
            {/* <ContainersToCollect /> */}
          </>
        ) : (
          <Box
            display="flex"
            justifyContent="center"
            alignItems="center"
            minHeight="100vh"
          >
            <CircularProgress
              value={100}
              size={56}
              thickness={4}
              sx={{ position: "absolute", top: "75%", left: "60%" }}
              disableShrink
            />
          </Box>
        )}
      </Container>
    </Page>
  );
}
