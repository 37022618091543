import { adminMenu } from "./AdminMenu";
import { B2BMenu } from "./B2BMenu";
import { dispatchMenu } from "./DispatchMenu";
import { qcMenu } from "./QCMenu";
import { facilityMenu } from "./FacilityMenu";
import { loadShareMenu } from "./LoadShareMenu";
import { platformOwnerMenu } from "./PlatformOwnerMenu";
import { restaurantOwnerMenu } from "./RestaurantOwnerMenu";
import { webMasterMenu } from "./WebmasterMenu";
import { cityHeadMenu } from "./CityHeadMenu";
import { shiftSuperMenu } from "./ShiftSuperMenu";
import { kAMMenu } from "./KAMMenu";
import { qcManMen } from "./QCManMenu";
import { cityViewMenu } from "./CityViewMenu";



const menuMapping = {
  1: {
    menuType: webMasterMenu,
  },
  2: {
    menuType: adminMenu,
  },
  3: {
    menuType: adminMenu,
  },
  4: {
    menuType: adminMenu,
  },
  5: {
    menuType: B2BMenu,
  },
  6: {
    menuType: adminMenu,
  },
  7: {
    menuType: restaurantOwnerMenu,
  },
  8: {
    menuType: platformOwnerMenu,
  },
  9: {
    menuType: loadShareMenu,
  },
  12: {
    menuType: facilityMenu,
  },
  13: {
    menuType: dispatchMenu,
  },
  16: {
    menuType: qcMenu,
  },
  17: {
    menuType: cityHeadMenu,
  },
  18: {
    menuType: shiftSuperMenu,
  },
  19: {
    menuType: qcManMen,
  },
  20: {
    menuType: kAMMenu,
  },
  21: {
    menuType: cityViewMenu,
  },
};

function getMenu(menuType) {
  return menuMapping[menuType];
}

export { getMenu };
