import SvgIconStyle from "../../../components/SvgIconStyle";
// ----------------------------------------------------------------------

export const getIcon = (name) => (
  <SvgIconStyle src={`/icons/${name}.svg`} sx={{ width: 1, height: 1 }} />
);
export const muiIcon=(name)=>(
  <SvgIconStyle src={"../../../assets/alert.svg"} sx={{ width: 1, height: 1 }} />
)

 const ICONS = {
  blog: getIcon("ic_blog"),
  cart: getIcon("ic_cart"),
  chat: getIcon("ic_chat"),
  mail: getIcon("ic_mail"),
  user: getIcon("ic_user"),
  kanban: getIcon("ic_kanban"),
  banking: getIcon("ic_banking"),
  booking: getIcon("ic_booking"),
  invoice: getIcon("ic_invoice"),
  calendar: getIcon("ic_calendar"),
  ecommerce: getIcon("ic_ecommerce"),
  analytics: getIcon("ic_analytics"),
  dashboard: getIcon("ic_dashboard"),
  authtokens: getIcon("ic_invoice"),
  alerts: getIcon("alert"),
  eodReport: muiIcon("document"),
  ledger: muiIcon("ledger"),
  grid: muiIcon("ledger"),
  overview: muiIcon("overview"),
  metrics: muiIcon("metrics"),
  history: muiIcon("history"),
  insights: muiIcon("insights"),
};

export default ICONS;
