import { noCase } from "change-case";
import PropTypes from "prop-types";
import { useEffect, useState } from "react";
// @mui
import {
  Avatar,
  Badge,
  Box,
  Button,
  Divider,
  List,
  ListItemAvatar,
  ListItemButton,
  ListItemText,
  ListSubheader,
  Typography,
} from "@mui/material";
// utils
import { fToNow } from "../../../utils/formatTime";
// _mock_
// components
import { getReqToken } from "src/firebaseInit";
import { getAllNotifications, markReadNotifications } from "src/service/api";
import Iconify from "../../../components/Iconify";
import MenuPopover from "../../../components/MenuPopover";
import Scrollbar from "../../../components/Scrollbar";
import { IconButtonAnimate } from "../../../components/animate";

// ----------------------------------------------------------------------

export default function NotificationsPopover() {
  const [notifications, setNotifications] = useState([]);

  const totalUnRead = notifications.filter(
    (item) => item.isUnRead === true
  ).length;

  const [open, setOpen] = useState(null);

  const handleOpen = (event) => {
    setOpen(event.currentTarget);
  };

  const handleClose = () => {
    setOpen(null);
  };

  const [isTokenFound, setTokenFound] = useState(false);

  async function tokenFunc() {
    const data = await getReqToken(setTokenFound);
    if (data) {
      // console.log("Token is", data);
    }
    return data;
  }
  useEffect(() => {
    function requestPermission() {
      // console.log("Requesting permission...");
      Notification.requestPermission().then((permission) => {
        if (permission === "granted") {
          tokenFunc();
          console.log("Notification permission granted.");
        }
      });
    }
    requestPermission();
  }, [setTokenFound]);

  const [rerender, setRerender] = useState(false);

  const fetchNotifications = async () => {
    const noti = await getAllNotifications();
    if (noti && noti.status === "Success") {
      const newNotifications = noti.data.rows.map((notif) => ({
        id: notif.messageId,
        createdAt: notif.created_at,
        isUnRead: notif.is_read === 0 ? true : false,
        title: notif.title,
        description: notif.message,
        type: "chat_message",
        avatar: null,
      }));

      setNotifications(newNotifications);
    }
  };

  useEffect(() => {
    fetchNotifications();
  }, [rerender]);

  const markNotificationAsRead = async (notification) => {
    try {
      const noti = await markReadNotifications(notification.id);
      if (noti.status === "Success") {
        setRerender(!rerender);
      }
    } catch (error) {
      console.error("Error marking notification as read:", error);
    }
  };

  return (
    <>
      <IconButtonAnimate
        color={open ? "primary" : "default"}
        onClick={handleOpen}
        sx={{ width: 40, height: 40 }}
      >
        <Badge badgeContent={totalUnRead} color="error">
          <Iconify icon="eva:bell-fill" width={20} height={20} />
        </Badge>
      </IconButtonAnimate>

      <MenuPopover
        open={Boolean(open)}
        anchorEl={open}
        onClose={handleClose}
        sx={{ width: 360, p: 0, mt: 1.5, ml: 0.75 }}
      >
        <Box sx={{ display: "flex", alignItems: "center", py: 2, px: 2.5 }}>
          <Box sx={{ flexGrow: 1 }}>
            <Typography variant="subtitle1">Notifications</Typography>
            <Typography variant="body2" sx={{ color: "text.secondary" }}>
              You have {totalUnRead} unread messages
            </Typography>
          </Box>
        </Box>

        <Divider sx={{ borderStyle: "dashed" }} />

        <Scrollbar
          sx={{
            height: {
              xs: 240, // Height for extra small screens
              sm: 360, // Height for small screens
              md: 480, // Height for medium screens
              lg: 600, // Height for large screens
            },
            overflowY: "auto", // Add this to enable vertical scrolling when content exceeds the container's height
          }}
        >
          <List
            disablePadding
            subheader={
              <ListSubheader
                disableSticky
                sx={{ py: 1, px: 2.5, typography: "overline" }}
              >
                New
              </ListSubheader>
            }
          >
            <div style={{ paddingBottom: "110px" }}>
              {notifications.map((notification) => (
                <NotificationItem
                  key={notification.id}
                  notification={notification}
                  onClick={markNotificationAsRead}
                />
              ))}
            </div>
          </List>
        </Scrollbar>
        <Divider sx={{ borderStyle: "dashed" }} />

        <Box sx={{ p: 1 }}>
          <Button fullWidth disableRipple>
            View All
          </Button>
        </Box>
      </MenuPopover>
    </>
  );
}

// ----------------------------------------------------------------------

NotificationItem.propTypes = {
  notification: PropTypes.shape({
    createdAt: PropTypes.instanceOf(Date),
    id: PropTypes.string,
    isUnRead: PropTypes.bool,
    title: PropTypes.string,
    description: PropTypes.string,
    type: PropTypes.string,
    avatar: PropTypes.any,
  }),
};

function NotificationItem({ notification, onClick }) {
  const { avatar, title } = renderContent(notification);

  const handleItemClick = () => {
    onClick(notification);
  };

  return (
    <ListItemButton
      sx={{
        py: 1.5,
        px: 2.5,
        mt: "1px",
        ...(notification.isUnRead && {
          bgcolor: "action.selected",
        }),
      }}
      onClick={handleItemClick}
    >
      <ListItemAvatar>
        <Avatar sx={{ bgcolor: "background.neutral" }}>{avatar}</Avatar>
      </ListItemAvatar>
      <ListItemText
        primary={title}
        secondary={
          <Typography
            variant="caption"
            sx={{
              mt: 0.5,
              display: "flex",
              alignItems: "center",
              color: "text.disabled",
            }}
          >
            <Iconify
              icon="eva:clock-outline"
              sx={{ mr: 0.5, width: 16, height: 16 }}
            />
            {fToNow(notification.createdAt)}
          </Typography>
        }
      />
    </ListItemButton>
  );
}

// ----------------------------------------------------------------------

function renderContent(notification) {
  const title = (
    <Typography variant="subtitle2">
      {notification.title}
      <Typography
        component="span"
        variant="body2"
        sx={{ color: "text.secondary" }}
      >
        &nbsp; {noCase(notification.description)}
      </Typography>
    </Typography>
  );

  if (notification.type === "order_placed") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_package.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "order_shipped") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_shipping.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "mail") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_mail.svg"
        />
      ),
      title,
    };
  }
  if (notification.type === "chat_message") {
    return {
      avatar: (
        <img
          alt={notification.title}
          src="https://minimal-assets-api.vercel.app/assets/icons/ic_notification_chat.svg"
        />
      ),
      title,
    };
  }
  return {
    avatar: notification.avatar ? (
      <img alt={notification.title} src={notification.avatar} />
    ) : null,
    title,
  };
}
