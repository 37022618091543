import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const restaurantOwnerMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  // const { title, path, more, products, tags, children } = parent;

  {
    subheader: "general",
    items: [
      {
        title: "Dashboard",
        path: PATH_DASHBOARD.general.dashboard,
        icon: ICONS.dashboard,
      },
      // {
      //   title: "Inventory",
      //   path: PATH_DASHBOARD.general.inventory,
      //   icon: ICONS.ecommerce,
      //   children: [
      //     {
      //       title: "general",
      //       path: PATH_DASHBOARD.general.inventory,
      //       icon: ICONS.alerts,
      //     },
      //     {
      //       title: "eod reports",
      //       path: PATH_DASHBOARD.general.eodReport,
      //       icon: ICONS.eodReport,
      //     },
      //   ],
      // },
      {
        title: "Reports",
        icon: ICONS.analytics,
        children: [
          {
            title: "Order Dispatch",
            path: PATH_DASHBOARD.general.dispatch,
            icon: ICONS.grid,
          },
        ],
      },
      {
        title: "B2B Batch Listing",
        path: PATH_DASHBOARD.general.b2bBatchListing,
        icon: ICONS.analytics,
      },
      // {
      //   title: "Order Listing",
      //   path: PATH_DASHBOARD.general.orderListing,
      //   icon: ICONS.analytics,
      // },
    ],
  },
];

export { restaurantOwnerMenu };
