import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const loadShareMenu = [
  // GENERAL
  // ----------------------------------------------------------------------
  // const { title, path, more, products, tags, children } = parent;
  {
    subheader: "general",
    items: [
      {
        title: "Reports",
        icon: ICONS.analytics,
        children: [
          {
            title: "Collection Agent Report",
            path: PATH_DASHBOARD.general.collectionReport,
          },
        ],
      },
    ],
  },
];

export { loadShareMenu };
