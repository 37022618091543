import React from "react";
import { Container } from "@mui/material";
import Page from "src/components/Page";
import useSettings from "src/hooks/useSettings";
import DataGrid from "src/components/data-grid/DataGrid";

export default function OrderListing() {
  const { themeStretch } = useSettings();
  const columnArr = [
    "order_no",
    "orderStatus",
    "opted_for_bag",
    "customer_name",
    "restaurantName",
    "totalContainers",
    "collected_at",
    "customer_phone",
    "collectionStatus",
  ];

  return (
    <Container maxWidth={themeStretch ? false : "xl"}>
      <Page title="Swiggy Weekly">
        <h2 id="header">Weekly Report</h2>
        <DataGrid page="weeklyReport" columnArr={columnArr} />
      </Page>
    </Container>
  );
}
