import { PATH_DASHBOARD } from "../../../../routes/paths";
import ICONS from "../NavIcons";

// ----------------------------------------------------------------------

const qcManMen = [
  {
    subheader: "general",
    items: [
      {
        title: "QC",
        icon: ICONS.alerts,
        children: [
          {
            title: "QC Listing",
            path: PATH_DASHBOARD.general.qcListing,
            icon: ICONS.grid,
          },
          {
            title: "QC Form",
            path: PATH_DASHBOARD.general.qcForm,
            icon: ICONS.grid,
          },
        ],
      },
        //Ops Details 
        {
          title: "Ops Details",
  
          children: [
  
            {
              title: "Ops Shift Report",
              children: [
                {
                  title: "Add Ops Shift Status",
                  path: PATH_DASHBOARD.general.addOpsShiftReport,
                },
                {
                  title: "Ops Shift Report listing",
                  path: PATH_DASHBOARD.general.opsShiftReportListing,
                },
              ],
            },
            {
              title: "Ops QC Report",
              children: [
                {
                  title: "Add Ops QC Status",
                  path: PATH_DASHBOARD.general.addOpsQCReport,
                },
                {
                  title: "Ops QC Report listing",
                  path: PATH_DASHBOARD.general.opsQCReportListing,
                },
              ],
            },
            {
              title: "Faclity Man Power",
              children: [
                {
                  title: "Add Facility Man Power Count",
                  path: PATH_DASHBOARD.general.addFacilityManPowerCount,
                },
                {
                  title: "Facility Man Power listing",
                  path: PATH_DASHBOARD.general.facilityManPowerCountListing,
                },
              ],
            },
            {
              title: "Water Efficiency",
              children: [
                {
                  title: "Add Water Efficiency",
                  path: PATH_DASHBOARD.general.addWaterEfficiency,
                },
                {
                  title: "Water Efficiency listing",
                  path: PATH_DASHBOARD.general.waterEfficiencyListing,
                },
              ],
            },
            {
              title: "Client Escalation",
              children: [
                {
                  title: "Add Client Escalation",
                  path: PATH_DASHBOARD.general.addClientEscalation,
                },
                {
                  title: "Client Escalation listing",
                  path: PATH_DASHBOARD.general.clientEscalationListing,
                },
              ],
            },
            {
              title: "Escalation Type",
              children: [
                {
                  title: "Add Escalation Type",
                  path: PATH_DASHBOARD.general.addEscalationType,
                },
                {
                  title: "Escalation Type listing",
                  path: PATH_DASHBOARD.general.escalationTypeListing,
                },
              ],
            },
          ],
        },
    ],
  },
];

export { qcManMen };
