import React, { useEffect, useState, useRef } from "react";
import SearchIcon from "@mui/icons-material/Search";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
// import AdapterDateFns from "@mui/lab/AdapterDateFns";
import { AdapterDateFns } from "@mui/x-date-pickers/AdapterDateFns";
import { useTheme } from "@mui/material/styles";
import _ from "lodash";
// import LocalizationProvider from "@mui/lab/LocalizationProvider";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import {
  Box,
  Collapse,
  Divider,
  MenuItem,
  Paper,
  Typography,
  Table,
  TableBody,
  TableContainer,
  TableSortLabel,
  TablePagination,
  TextField,
  TableRow,
  TableCell,
  TableHead,
  Checkbox,
  IconButton,
  OutlinedInput,
  CircularProgress,
  Button,
  FormControl,
  Select,
  InputLabel,
  ListItemText,
} from "@mui/material";
import moment from "moment";
import "../../styles/dataGrid.css";
import Iconify from "../Iconify";
import {
  GetGridata,
  getOrderComments,
  getWeeklyReportCard,
  getWeeklyReport,
} from "src/service/api";
import { visuallyHidden } from "@mui/utils";
import { _invoices } from "../../_mock/_invoice";
import { truncate } from "lodash";
import { CSVLink } from "react-csv";
import { DesktopDatePicker } from "@mui/x-date-pickers/DesktopDatePicker";
import Label from "../Label";
import MetricsCards from "./MetricsCards";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSnackbar } from "notistack";

function DataGrid({ page, columnArr }) {
  const [fromDate, setFromDate] = useState(
    moment().subtract(7, "d").format("YYYY-MM-DD")
  );
  const [toDate, setToDate] = useState(moment().format("YYYY-MM-DD"));
  const [ButtonDate, setButtonDate] = useState(null);
  const [filter, setFilter] = useState("");
  const [filterName, setFilterName] = useState("");
  const [sort, setSort] = useState("asc");
  const [pageNumber, setPageNumber] = useState(1);
  const [pageSize, setPageSize] = useState(10);
  const [sortField, setSortField] = useState("");
  const [total, setTotal] = useState(0);
  const [analysisReport, setAnalysisReport] = useState(null);
  const [timer, setTimer] = useState("");
  const [fetched, setFetched] = useState(false);
  const [data, setData] = useState([]);
  const [column, setColumn] = useState([]);
  const [sortOrder, setSortOrder] = useState([]);
  const { enqueueSnackbar } = useSnackbar();
  const theme = useTheme();
  const [open, setOpen] = useState(-1);
  const [comments, setComments] = useState([]);
  const [downloadData, setDownloadData] = useState([]);
  const [isOpen, setIsOpen] = useState(true);
  const [loading, setLoading] = useState(true);
  const [loader, setLoader] = useState(false);

  const csvInstance = useRef(null);

  const containerNameMapping = {
    "5 CP plates": "5 CP plates",
    "3 CP plates": "3 CP plates",
    "100ml (Circular)": "100ml (C)",
    "250ml (Rectangular)": "250ml (R)",
    "500ml (Rectangular)": "500ml (R)",
    "600ml (Rectangular)": "600ml (R)",
    "800ml (Rectangular)": "800ml (R)",
  };

  let TABLE_HEAD = [];
  const onSort = (el) => {
    if (el !== "") {
      let isAsc = false;
      if (sort === "asc") {
        isAsc = true;
      }
      setSort(isAsc ? "desc" : "asc");
      setSortField(el);
    }
  };

  const getWeekly = () => {
    setFilter("");
    setFilterName("");
    setPageNumber(1);
    setPageSize(10);
    setToDate(moment().format("YYYY-MM-DD"));
    setFromDate(moment().subtract(7, "d").format("YYYY-MM-DD"));
    setFetched(false);
    setButtonDate(
      `${moment().subtract(7, "d").format("YYYY-MM-DD")}:${moment().format(
        "YYYY-MM-DD"
      )}`
    );
  };

  const getDaily = () => {
    setFilter("");
    setFilterName("");
    setPageSize(10);
    setPageNumber(1);
    setFromDate(moment().format("YYYY-MM-DD"));
    setToDate(moment().format("YYYY-MM-DD"));
    setFetched(false);
    setButtonDate(
      `${moment().format("YYYY-MM-DD")}:${moment().format("YYYY-MM-DD")}`
    );
  };

  async function getCommentsData(order_id, index) {
    setIsOpen((current) => !current);
    if (isOpen) {
      let orderComment = await getOrderComments(order_id);
      // console.log("ORDER COMMENTS", comments);
      setComments(orderComment.data.comments);
    }

    setOpen(open === index ? -1 : index);
  }

  const handleColumnChange = async (event) => {
    // console.log(event)
    const value = event.target.value;
    // const {
    //     target: { value },
    // } = event;
    // console.log({"value":event.target.value })

    // // setColumn(
    // //     typeof value === 'string' ? value.split(',') : value,
    // console.log({ prev: sortOrder });
    // console.log({ vaal: value });
    // // );
    const sorter = (a, b) => {
      return sortOrder.indexOf(a) - sortOrder.indexOf(b);
    };
    value.sort(sorter);

    // console.log({ vaal: value });
    // console.log({ "arr": arr })

    // console.log(arr)
    setColumn(value);
    // console.log({ col: column });
  };

  const onChangePage = (event, newPage) => {
    setPageNumber(newPage + 1);
  };

  const onChangeRowsPerPage = (event) => {
    setPageSize(parseInt(event.target.value, 10));
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const date =
          toDate.length > 0 && fromDate.length > 0
            ? `${fromDate}:${toDate}`
            : "";

        setLoading(true);
        if (page !== "weeklyReport") {
          let res = await GetGridata(
            filter,
            filterName,
            pageNumber,
            pageSize,
            sortField,
            sort,
            date,
            page
          );

          setData(res.rows);
          setTotal(res.total);
          setSortOrder(Object.keys(res.rows[0]));
          columnArr
            ? setColumn(columnArr)
            : setColumn(Object.keys(res.rows[0]));
        } else if (page === "weeklyReport") {
          if (!fetched) {
            let card = await getWeeklyReportCard(
              fromDate,
              toDate,
              filter,
              filterName
            );
            if (card.status === "Success") {
              setAnalysisReport(card.data);
              setFetched(true);
            }
          }
          let report = await getWeeklyReport(
            fromDate,
            toDate,
            pageNumber,
            pageSize,
            filter,
            filterName
          );
          if (report.status === "success") {
            setTotal(report.totalOrders);
            setData(report.orderDetails);
            setSortOrder(Object.keys(report.orderDetails[0]));
            columnArr
              ? setColumn(columnArr)
              : setColumn(Object.keys(report.orderDetails[0]));
          } else if (report.status_code === 500) {
            setData([]);
            enqueueSnackbar("No data available for specified date/filter", {
              variant: "warning",
            });
          } else {
            enqueueSnackbar("Something went wrong.", {
              variant: "error",
            });
          }
        }
      } catch (error) {
        console.log(error.message);
      }
      setLoading(false);
    };
    fetch();

    setTimer(moment().format("h:mm a"));
  }, [pageNumber, pageSize, ButtonDate]);

  const ITEM_HEIGHT = 48;
  const ITEM_PADDING_TOP = 8;
  const MenuProps = {
    PaperProps: {
      style: {
        maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
        width: 250,
      },
    },
  };

  const handleFilterName = (filterName) => {
    setFilterName(filterName);
  };

  // const handleKeypress = (e) => {
  //   if (e.keyCode === 13) {
  //     loadData("search");
  //   }
  // };

  const loadData = async (from) => {
    setLoading(true);

    try {
      let date = `${fromDate}:${toDate}`;
      if (from === "button") {
        date = ButtonDate;
      }

      // console.log({ yes: date });
      // let res = await GetGridata(
      //   filter,
      //   filterName,
      //   pageNumber,
      //   pageSize,
      //   sortField,
      //   sort,
      //   date,
      //   page
      // );
      // if (page === "weeklyReport") {
      //   setData(res.result.rows);
      //   setTotal(res.result.total);
      //   setAnalysisReport(res.analysisReport);
      //   if (column.length === 0) {
      //     columnArr
      //       ? setColumn(columnArr)
      //       : setColumn(Object.keys(res.result.rows[0]));
      //   }
      //   if (sortOrder.length === 0) {
      //     setSortOrder(Object.keys(res.result.rows[0]));
      //   }
      // }

      if (page !== "weeklyReport") {
        let res = await GetGridata(
          filter,
          filterName,
          pageNumber,
          pageSize,
          sortField,
          sort,
          date,
          page
        );

        setData(res.rows);
        setTotal(res.total);
        if (column.length === 0) {
          columnArr
            ? setColumn(columnArr)
            : setColumn(Object.keys(res.rows[0]));
        }
        if (sortOrder.length === 0) {
          setSortOrder(Object.keys(res.rows[0]));
        }
      } else if (page === "weeklyReport") {
        if (pageNumber !== 1) {
          setFetched(false);
          setPageNumber(1);
        } else {
          let card = await getWeeklyReportCard(
            fromDate,
            toDate,
            filter,
            filterName
          );
          if (card.status === "Success") {
            setAnalysisReport(card.data);
            setFetched(true);
          }

          let report = await getWeeklyReport(
            fromDate,
            toDate,
            pageNumber,
            pageSize,
            filter,
            filterName
          );
          if (report.status === "success") {
            setTotal(report.totalOrders);
            setData(report.orderDetails);
            if (column.length === 0) {
              columnArr
                ? setColumn(columnArr)
                : setColumn(Object.keys(report.orderDetails[0]));
            }
            if (sortOrder.length === 0) {
              setSortOrder(Object.keys(report.orderDetails[0]));
            }
          } else if (report.status_code === 500) {
            setData([]);
            enqueueSnackbar("No data available for specified date/filter", {
              variant: "warning",
            });
          } else {
            enqueueSnackbar("Something went wrong.", {
              variant: "error",
            });
          }
        }
      }

      setTimer(moment().format("h:mm a"));
    } catch (error) {
      setLoading(false);
      console.log(error);
    }
    setLoading(false);
  };

  const downloadReport = async () => {
    if (page === "weeklyReport") {
      setLoader(true);
      try {
        let res = await getWeeklyReport(fromDate, toDate, null, null);
        if (res.status === "success") {
          setDownloadData(res.orderDetails);

          csvInstance.current.link.click();
          setLoader(false);
        } else {
          setLoader(false);
          enqueueSnackbar("Something went wrong", { variant: "error" });
        }
      } catch (error) {
        setLoader(false);
        enqueueSnackbar("Download Error", { variant: "error" });
      }
    }
  };

  // useEffect(() => {
  //   setLoading(true);
  //   loadData("search");
  //   console.log("search");
  //   setLoading(false);
  // }, [pageNumber, pageSize, sortField, sort]);

  // useEffect(() => {
  //   setLoading(true);
  //   loadData("button");
  //   console.log("button");
  //   setLoading(false);
  // }, [ButtonDate]);

  return (
    <Paper className="main-div">
      <div
        style={{
          flex: 1,
          flexDirection: "row",
          alignSelf: "flex-end",
          justifyContent: "flex-end",
          marginBottom: "2rem",
        }}
      >
        <div>{`Last synced at: ${timer}`}</div>
      </div>
      <Paper elevation={10} className="search-bar">
        <div className="filterline1">
          <LocalizationProvider
            className="from-date"
            dateAdapter={AdapterDateFns}
          >
            <DesktopDatePicker
              // onKeyPress={handleKeypress}
              label="From Date"
              inputFormat="dd/MM/yyyy"
              value={fromDate}
              onChange={(newValue) => {
                const date = moment(newValue).format("YYYY-MM-DD");

                setFromDate(date);
                if (fetched) {
                  setFetched(false);
                }
              }}
              renderInput={(params) => <TextField size={"small"} {...params} />}
            />
          </LocalizationProvider>
          <Divider
            className="divider"
            style={{ height: "3vw" }}
            orientation="vertical"
          />
          <LocalizationProvider
            className="from-date"
            dateAdapter={AdapterDateFns}
          >
            <DesktopDatePicker
              label="To Date"
              inputFormat="dd/MM/yyyy"
              value={toDate}
              onChange={(newValue) => {
                const date = moment(newValue).format("YYYY-MM-DD");
                setToDate(date);
                if (fetched) {
                  setFetched(false);
                }
              }}
              renderInput={(params) => <TextField size={"small"} {...params} />}
            />
          </LocalizationProvider>
          <Divider
            className="divider"
            style={{ height: "3vw" }}
            orientation="vertical"
            flexItem
          />
          <div className="filter-bar">
            <FormControl className="filter-bar-form" size={"small"}>
              <InputLabel
                style={{ margin: "0rem 0.5vw" }}
                id="demo-simple-select-label"
              >
                Filter By
              </InputLabel>
              <Select
                labelId="demo-simple-select-label"
                style={{ minWidth: "10rem" }}
                label="Filter By"
                disabled={column === null || column.length === 0}
                value={filter === null || filter.length === 0 ? "" : filter}
                onChange={(e) => setFilter(e.target.value)}
              >
                {column &&
                  column.length !== 0 &&
                  column.map((el, idx) => {
                    if (
                      page === "weeklyReport" &&
                      el !== "totalContainers" &&
                      el !== "collected_at"
                    ) {
                      return (
                        <MenuItem key={idx} value={el}>
                          {el}
                        </MenuItem>
                      );
                    } else if (page !== "weeklyReport") {
                      return (
                        <MenuItem key={idx} value={el}>
                          {el}
                        </MenuItem>
                      );
                    }
                  })}
              </Select>
            </FormControl>
          </div>
          <Divider
            className="divider"
            style={{ margin: "0rem 0.5vw", height: "3vw" }}
            orientation="vertical"
            flexItem
          />

          <div className="filter-bar">
            <TextField
              size={"small"}
              style={{ minWidth: "10rem" }}
              labelId="demo-simple-select-label"
              label="Filter"
              fullWidth
              disabled={filter === null || filter.length === 0}
              value={filterName && filterName.length ? filterName : ""}
              onChange={(event) =>
                handleFilterName(event.target.value.toString())
              }
              placeholder="Search"
            />
          </div>

          <Divider
            className="divider"
            style={{ margin: "0rem 0.5vw", height: "3vw" }}
            orientation="vertical"
            flexItem
          />
          <FormControl
            size={"small"}
            sx={{ width: "15rem", marginRight: "1vw" }}
          >
            <InputLabel id="demo-multiple-checkbox-label">Column</InputLabel>
            <Select
              labelId="demo-multiple-checkbox-label"
              id="demo-multiple-checkbox"
              multiple
              value={column}
              onChange={handleColumnChange}
              input={<OutlinedInput label="Tag" />}
              renderValue={(selected) => selected.join(", ")}
              MenuProps={MenuProps}
            >
              {data !== null &&
                data.length !== 0 &&
                Object.keys(data[0]).map((name) => {
                  // console.log({ "inex": column.indexOf(name) })
                  return (
                    <MenuItem key={name} value={name}>
                      <Checkbox checked={column.indexOf(name) > -1} />
                      <ListItemText primary={name} />
                    </MenuItem>
                  );
                })}
            </Select>
          </FormControl>
        </div>
        <div
          style={{
            display: "flex",
            flexDirection: "row",
            alignItems: "flex-end",
            paddingBottom: "1rem",
            justifyContent: "space-evenly",
            width: "100%",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton color="primary" onClick={downloadReport}>
              {!loader ? (
                <Iconify
                  style={{ height: "2vw", width: "2vw" }}
                  icon={"eva:download-outline"}
                />
              ) : (
                <CircularProgress color="inherit" size="1.5rem" />
              )}
            </IconButton>
            <CSVLink
              asyncOnClick={true}
              data={downloadData}
              filename={`${fromDate}to${toDate}_weeklyReport`}
              ref={csvInstance}
            />
            <Typography style={{ fontSize: "1vw", color: "#00AB55" }}>
              Download CSV
            </Typography>
          </div>
          <Button
            style={{
              height: "3vw",
              width: "15vw",
              color: "#005400",
              fontSize: "1vw",
            }}
            onClick={getWeekly}
            variant="contained"
          >
            Last week's Data
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton color="primary" onClick={loadData}>
              <SearchIcon style={{ height: "3vw", width: "3vw" }} />
            </IconButton>
            <Typography
              style={{
                fontSize: "1vw",
                color: "#00AB55",
                fontWeight: "bolder",
              }}
            >
              Click here to search
            </Typography>
          </div>
          <Button
            onClick={getDaily}
            style={{
              height: "3vw",
              width: "15vw",
              color: "#005400",
              fontSize: "1vw",
            }}
            variant="contained"
          >
            Today's data
          </Button>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            <IconButton color="primary" onClick={getWeekly}>
              <RestartAltIcon style={{ height: "2vw", width: "2vw" }} />
            </IconButton>
            <Typography style={{ fontSize: "1vw", color: "#00AB55" }}>
              Reset
            </Typography>
          </div>
        </div>
      </Paper>
      {!loading ? (
        <>
          {page === "weeklyReport" && (
            <MetricsCards analysis={analysisReport} page={page} />
          )}
          <Paper style={{ elevation: 10 }}>
            <TableContainer
              sx={{ marginTop: "2vh", overflow: "scroll", width: "80vw" }}
            >
              {data !== null && data.length !== 0 && (
                <Table
                  sx={{
                    borderRadius: "20rem",
                  }}
                >
                  {/* <TableHeadCustom
                            order={sort}
                            orderBy={filterName}
                            headLabel={TABLE_HEAD}
                            rowCount={data.length}
                            numSelected={selected.length}
                            onSort={onSort}
                            onSelectAllRows={(checked) =>
                                onSelectAllRows(
                                    checked,
                                    data.map((row) => row.order_id)
                                )
                            }
                        /> */}
                  <TableHead>
                    <TableRow style={{ overflowX: "auto" }}>
                      {page === "weeklyReport" && <TableCell />}

                      {column.map((el, idx) => (
                        <TableCell
                          className="table-head"
                          sx={{
                            px: 0,
                            mx: 0,
                          }}
                          align="center"
                          key={idx}
                        >
                          <TableSortLabel
                            active={sortField === el}
                            direction={sortField === el ? sort : "asc"}
                            onClick={() => onSort(el)}
                            sx={{ textTransform: "capitalize" }}
                          >
                            {el !== null
                              ? _.camelCase(el).replace(
                                  /([A-Z])([A-Z])([a-z])|([a-z])([A-Z])/g,
                                  "$1$4 $2$3$5"
                                )
                              : "-"}
                          </TableSortLabel>
                          {sortField === el ? (
                            <Box component="span" sx={visuallyHidden}>
                              {sort === "desc"
                                ? "sorted descending"
                                : "sorted ascending"}
                            </Box>
                          ) : null}
                        </TableCell>
                      ))}
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {data.map((row, index) => (
                      <>
                        <TableRow
                          style={{ overflowX: "auto" }}
                          key={index}
                          hover
                          sx={{
                            "&:last-child td, &:last-child th": { border: 0 },
                          }}
                        >
                          {page === "weeklyReport" && (
                            <TableCell>
                              <IconButton
                                aria-label="expand row"
                                size="small"
                                // onClick={() => setOpen(open === index ? -1 : index)}
                                onClick={() => getCommentsData(row.id, index)}
                              >
                                {open === index ? (
                                  <KeyboardArrowUpIcon />
                                ) : (
                                  <KeyboardArrowDownIcon />
                                )}
                              </IconButton>
                            </TableCell>
                          )}

                          {Object.entries(row).map((el, idx) => {
                            if (column.find((element) => element === el[0])) {
                              if (
                                el[0] === "collected_at" ||
                                el[0] === "scheduled_at"
                              ) {
                                return (
                                  <TableCell
                                    style={{ fontSize: 14, fontWeight: "500" }}
                                    align="center"
                                    key={idx}
                                    sx={{
                                      px: 0,
                                      mx: 0,
                                    }}
                                  >
                                    {el[1] &&
                                      moment(el[1]).format(
                                        "MM/DD/YYYY, h:mm a"
                                      )}
                                  </TableCell>
                                );
                              } else if (el[0] === "container_type") {
                                return (
                                  <TableCell
                                    style={{ fontSize: 14, fontWeight: "500" }}
                                    align="center"
                                    key={idx}
                                    sx={{
                                      px: 0,
                                      mx: 0,
                                    }}
                                  >
                                    {el[1] && containerNameMapping[el[1]]}
                                  </TableCell>
                                );
                              } else if (el[0] === "order_status") {
                                return (
                                  <TableCell align="center" key={idx}>
                                    <Label
                                      variant={
                                        theme.palette.mode === "light"
                                          ? "ghost"
                                          : "filled"
                                      }
                                      color={
                                        (el[1] === "With Collection Agent" &&
                                          "primary") ||
                                        (el[1] === "Available" && "info") ||
                                        (el[1] === " With Customer" &&
                                          "success") ||
                                        (el[1] === "Finalized" && "success") ||
                                        (el[1] === "In Progress" &&
                                          "warning") ||
                                        (el[1] === "Cancelled" && "error") ||
                                        "error"
                                      }
                                    >
                                      {el[1]}
                                    </Label>
                                  </TableCell>
                                );
                              } else {
                                return (
                                  <TableCell
                                    sx={{
                                      px: 0,
                                      mx: 0,
                                    }}
                                    style={{ fontSize: 14, fontWeight: "500" }}
                                    align="center"
                                    key={idx}
                                  >
                                    {truncate(el[1], { length: 40 })}
                                  </TableCell>
                                );
                              }
                            }
                          })}
                        </TableRow>
                        {page === "weeklyReport" && (
                          <TableRow>
                            <TableCell
                              style={{ paddingBottom: 0, paddingTop: 0 }}
                              colSpan={6}
                            >
                              <Collapse
                                in={open === index}
                                timeout="auto"
                                unmountOnExit
                              >
                                <strong>Order Comments:</strong>

                                {comments.length !== 0 ? (
                                  comments.map((comment, index) => {
                                    return (
                                      <Box
                                        sx={{
                                          border: 1,

                                          flexDirection: "row",
                                        }}
                                        m={1.5}
                                        p={1}
                                      >
                                        {comment.comment}
                                      </Box>
                                    );
                                  })
                                ) : (
                                  <Box
                                    sx={{
                                      border: 1,

                                      borderColor: "red",
                                      flexDirection: "row",
                                    }}
                                    m={1.5}
                                    p={1}
                                  >
                                    No comments
                                  </Box>
                                )}
                              </Collapse>
                            </TableCell>
                          </TableRow>
                        )}
                      </>
                    ))}
                  </TableBody>
                </Table>
              )}
            </TableContainer>
          </Paper>
          {data !== null && data.length !== 0 && (
            <Box sx={{ position: "relative" }}>
              <TablePagination
                rowsPerPageOptions={[5, 10, 25, total]}
                component="div"
                count={total}
                rowsPerPage={pageSize}
                page={pageNumber - 1}
                onPageChange={onChangePage}
                onRowsPerPageChange={onChangeRowsPerPage}
              />
            </Box>
          )}
        </>
      ) : (
        <Box
          display="flex"
          justifyContent="center"
          alignItems="center"
          minHeight="100vh"
        >
          <CircularProgress
            value={60}
            size={56}
            thickness={4}
            sx={{ position: "absolute", top: "65%", left: "60%" }}
            disableShrink
          />
        </Box>
      )}
    </Paper>
  );
}

export default DataGrid;
