import axios from "axios";
// config
import {
  HOST_API,
  Inventory_API,
  Reporting_API,
  SATGE_V2_API,
  Algorithm_API,
} from "../config";

// ----------------------------------------------------------------------

const axiosInstance = axios.create({
  baseURL: HOST_API,
  timeout: 1000 * 60 * 5,
  headers: { "Content-Type": "application/json" },
});

axiosInstance.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

const axiosInstanceV2 = axios.create({
  baseURL: Algorithm_API,
  timeout: 1000 * 60 * 5,
  headers: { "Content-Type": "application/json" },
});

axiosInstanceV2.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

const axiosInstanceInventory = axios.create({
  baseURL: Inventory_API,
  timeout: 1000 * 60 * 5,
  headers: { "Content-Type": "application/json" },
});

axiosInstanceInventory.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);
const axiosInstanceReporting = axios.create({
  baseURL: Reporting_API,
  timeout: 1000 * 60 * 5,
  headers: { "Content-Type": "application/json" },
});

axiosInstanceReporting.interceptors.response.use(
  (response) => response,
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);
const axiosInstanceStageV2 = axios.create({
  baseURL: SATGE_V2_API,
  timeout: 1000 * 60 * 5,
  headers: {
    "Content-Type": "application/json",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

// axiosInstanceStageV2.interceptors.response.use(
//   (response) => response,
//   (error) =>
//     Promise.reject(
//       (error.response && error.response.data) || "Something went wrong"
//     )
// );

axiosInstanceStageV2.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("accessToken");

    if (user) {
      config.headers.Authorization = `Bearer ${user}`;
    }

    return config;
  },
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

const axiosInstanceStageV3 = axios.create({
  baseURL: SATGE_V2_API,
  timeout: 1000 * 60 * 5,
  headers: {
    "Content-Type": "multipart/form-data",
    Authorization: `Bearer ${localStorage.getItem("accessToken")}`,
  },
});

axiosInstanceStageV3.interceptors.request.use(
  (config) => {
    const user = localStorage.getItem("accessToken");

    if (user) {
      config.headers.Authorization = `Bearer ${user}`;
    }

    return config;
  },
  (error) =>
    Promise.reject(
      (error.response && error.response.data) || "Something went wrong"
    )
);

export default {
  axiosInstance,
  axiosInstanceV2,
  axiosInstanceInventory,
  axiosInstanceReporting,
  axiosInstanceStageV2,
  axiosInstanceStageV3,
};
